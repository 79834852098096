<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <div v-if="is_loading_content" class="col-12">
          <Skeleton v-if="is_loading_content" width="100%" height="800px"></Skeleton>
        </div>
        <div v-else>
          <div class="col-12">
            <h5>Update Attribute</h5>
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                <label for="attribute-name">Attribute Name</label>
                <InputText v-model="form.attr_name" id="attribute-name" type="text" />
              </div>
              <div class="field col-12">
                <label for="attribute-desc">Attribute Description</label>
                <Textarea v-model="form.attr_desc" id="attribute-desc" rows="4" />
              </div>
              <div class="field col-12 md:col-12" style="display: flex; align-items: center; gap: 1rem">
                <div class="" style="display: flex; align-items: center; gap: 1rem">
                  <label for="weight-desc">Card Type ?</label>
                </div>
                <Dropdown v-model="form.type" :options="card_types" optionLabel="name" placeholder="Select" />
              </div>
              <div class="field col-12 md:col-3" style="display: flex; align-items: center; gap: 1rem">
                <div class="" style="display: flex; align-items: center; gap: 1rem">
                  <label for="weight-desc">Use Value input ?</label>
                </div>
                <InputSwitch v-model="form.is_use_value_input" />
              </div>
              <div class="field col-12 md:col-9" style="display: flex; align-items: center; gap: 1rem">
                <div class="" style="display: flex; align-items: center; gap: 1rem">
                  <label for="weight-desc">Use multiple Value input ?</label>
                </div>
                <InputSwitch
                  v-model="form.is_multiple_input"
                  @change="
                    () => {
                      form.is_use_value_input = form.is_multiple_input;
                    }
                  "
                />
              </div>
              <!-- break -->
            </div>
            <Button
              :loading="loading.update"
              @click="updateAttribute"
              :label="loading.update ? 'Please Wait' : 'Update Attribute Name'"
              class="mr-2 mb-2"
            />
          </div>

          <!-- Atttribute -->
          <div v-if="attribute_list.length" class="">
            <div v-for="(list, index) in attribute_list" :key="index + 5" class="col-12">
              <div class="p-fluid formgrid grid">
                <div class="field col-12" style="display: flex; align-items: center">
                  <span style="font-size: 1.2rem">Attribute List {{ `(${index + 1})` }}</span>
                </div>
                <div class="field col-1"></div>
              </div>
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4">
                  <label for="firstname2">Name</label>
                  <InputText v-model="list.name" id="firstname2" type="text" />
                </div>
                <div class="field col-12">
                  <label for="lastname2">Attribute Description</label>
                  <Textarea v-model="list.description" id="address" rows="4" />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="address">Price</label>
                  <InputNumber v-model="list.price" showButtons mode="decimal"></InputNumber>
                </div>
                <div class="field col-12 md:col-4">
                  <label for="city">Discount price (optional)</label>
                  <InputNumber v-model="list.discount_price" showButtons mode="decimal"></InputNumber>
                </div>
                <div class="field col-12 md:col-4">
                  <label for="city">Qty</label>
                  <InputNumber v-model="list.qty" showButtons mode="decimal"></InputNumber>
                </div>
                <div class="field col-12">
                  <label for="city">Weight (KG)</label>
                  <InputNumber v-model="list.weight" showButtons mode="decimal"></InputNumber>
                </div>
                <div class="field col-12 md:col-4">
                  <input
                    @change="
                      (e) => {
                        fileHandler(e, index);
                      }
                    "
                    type="file"
                  />

                  <div class="mt-5">
                    <h5>Image preview :</h5>
                    <img width="200" :src="list.preview" alt="" />
                  </div>
                </div>

                <!-- attribute price rule -->
                <div class="col-12">
                  <div class="col-3">
                    <Button label="Add Price rules" class="p-button-outlined mr-2 mb-2 mt-5" @click="AddPriceRule(index)" />
                  </div>
                  <div class="col">
                    <Card class="mb-2" v-for="(data, i) in list.price_rules" :key="i + 102">
                      <template v-slot:title>
                        <div class="flex align-items-center justify-content-between mb-0">
                          <p>Price rules #{{ i + 1 }}</p>
                          <Button icon="pi pi-times" class="p-button-text" @click="RemovePriceRules(index, i)" />
                        </div>
                      </template>

                      <template v-slot:content>
                        <MultiSelect
                          display="chip"
                          class="mt-2 mb-5"
                          v-model="attribute_list[index].price_rules[i].attr_rule"
                          :options="multiselectValues"
                          optionLabel="name"
                          placeholder="Select Countries"
                          :filter="true"
                        >
                          <template #value="slotProps">
                            <template v-if="!slotProps.value || slotProps.value.length === 0">
                              <div class="p-1">Select Attributes</div>
                            </template>
                          </template>
                        </MultiSelect>
                        <div class="formgrid grid">
                          <div class="field col">
                            <label for="name2">price</label>
                            <InputText v-model="attribute_list[index].price_rules[i].price" id="name2" type="text" />
                          </div>
                          <div class="field col">
                            <label for="email2">discount price</label>
                            <InputText v-model="attribute_list[index].price_rules[i].discount_price" id="email2" type="text" />
                          </div>
                        </div>
                      </template>
                    </Card>
                  </div>
                </div>
              </div>
              <Button
                :loading="loading.remove"
                @click="removeConfirm(list._id, index)"
                :label="loading.remove ? 'Please Wait' : 'Remove'"
                class="p-button-danger mr-2 mb-2"
                icon=" pi pi-trash"
              />
            </div>
          </div>

          <!-- END ATTR ASU GIAZ GAK DI BATESIN ASUUU -->
          <div class="" style="display: flex; width: 100%; justify-content: center">
            <Button @click="addAttrForm" label="Add List" class="mr-2 mb-2 p-button-secondary" />
            <Button :disabled="!attribute_list.length" @click="showModalSaveAll = true" label="Update" class="mr-2 mb-2" />
          </div>
        </div>
      </div>
    </div>

    <Dialog v-model:visible="showModalSaveAll" :style="{ width: '450px' }" header="Submit Attribute Confirm ?" :modal="true">
      <div class="flex align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Are you sure submit all attribute list ?</span>
      </div>
      <template #footer>
        <Button @click="showModalSaveAll = !showModalSaveAll" label="No" icon="pi pi-times" class="p-button-text" />
        <Button label="Yes" icon="pi pi-check" class="p-button-text" :loading="loading.update" @click="submitAttrList" />
      </template>
    </Dialog>
    <Dialog v-model:visible="showModalDelete" :style="{ width: '450px' }" header="Remove Attribute List Confirm ?" :modal="true">
      <div class="flex align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Are you sure to remove ?</span>
      </div>
      <template #footer>
        <Button @click="showModalDelete = !showModalDelete" label="No" icon="pi pi-times" class="p-button-text" />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          :loading="loading.remove"
          @click="removeList(temp_remove_id)"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import AttributeApi from "../../api/Attribute";
import BaseURL from "../../api/BaseURL";

export default {
  data() {
    return {
      baseUrl: BaseURL,
      showModalSaveAll: false,
      showModalDelete: false,
      multiselectValue: null,
      is_loading_content: false,
      multiselectValues: [],
      temp_remove_id: "",
      form: {
        attr_name: "",
        attr_desc: "",
        is_multiple_input: false,
        is_use_value_input: false,
      },
      card_types: [
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
        { name: 4, value: 4 },
        { name: 5, value: 5 },
        { name: 6, value: 6 },
        { name: 7, value: 7 },
        { name: 8, value: 8 },
        { name: 9, value: 9 },
      ],
      attribute_list: [],
      files: [],
      price_rules: [],
      loading: {
        add: false,
        update: false,
        delete: false,
      },
    };
  },
  mounted() {},
  async created() {
    this.is_loading_content = true;
    await this.getAtrributeDetails();
    this.is_loading_content = false;
  },
  methods: {
    fileHandler(e, index) {
      this.attribute_list[index].file = e.target.files[0];
      this.attribute_list[index].preview = e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "";
    },
    addAttrForm() {
      const id = this.$route.params.id;
      this.attribute_list.push({
        new: true,
        desktop_img: "",
        mobile_img: "",
        is_variant: false,
        variants: [],
        price_rules: [],
        price: 0,
        discount_price: 0,
        name: "",
        description: "",
        qty: 0,
        file: null,
        preview: null,
        product_attribute_id: id,
        type: null,
        is_use_value_input: false,
      });
      this.price_rules.push({
        attr_list: [],
        is_variant_rule: false,
        price: null,
        discount_price: null,
        attr_list_id: null,
        is_use_price_rules: false,
      });
    },
    async updateAttribute() {
      this.loading.update = true;
      try {
        const response = await AttributeApi.Update(this.$route.params.id, {
          ...this.form,
          type: this.form.type.value,
        });

        if (response.data.status === 200) {
          this.loading.update = false;
          this.$toast.add({
            severity: "success",
            summary: "Attribute Updated",
            detail: "Successfully Updated",
            life: 4000,
          });
          this.getAtrributeDetails();
        } else {
          this.loading.update = false;
          this.$toast.add({
            severity: "error",
            summary: "Something went wrong!",
            detail: response.data.message,
            life: 4000,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    RemovePriceRules(idx, i) {
      this.attribute_list[idx].price_rules.splice(i, 1);
    },
    AddPriceRule(idx) {
      this.attribute_list[idx].price_rules.push({
        attr_rule: [],
        is_variant_rule: false,
        variant_rule: [],
        price: null,
        discount_price: null,
        attr_list_id: null,
      });
    },
    async getAtrributeDetails() {
      const id = this.$route.params.id;
      const response = await AttributeApi.Details(id);
      let geting = await AttributeApi.GetAllAttribute();
      geting?.data?.data.map((el) => {
        this.multiselectValues.push({
          name: `${el?.product_attribute_id?.attr_name} - ${el?.name}`,
          value: el._id,
        });
      });
      if (response.data.status === 200) {
        this.form = {
          ...response.data.data,
          type: {
            name: response.data.data.type,
            value: response.data.data.type,
          },
        };
        let temp_list = [];

        if (!response.data.data.attributes_list.length) {
          this.attribute_list.push({
            id: this.attribute_list.length + 1,
            price: 0,
            discount_price: 0,
            name: "",
            description: "",
            qty: 0,
            file: null,
            preview: null,
            product_attribute_id: this.$route.params._id,
          });
        }

        response.data.data.attributes_list?.map((list) => {
          let price_rules = list.price_rules.map((el) => {
            let attr = el.attr_rule.map((el) => {
              let find_data = this.multiselectValues.find((data) => data.value.toString() == el.toString());
              if (find_data) {
                return {
                  name: find_data?.name,
                  value: find_data?.value,
                };
              }
            });
            attr = attr.filter((el) => el != null);
            el["attr_rule"] = attr;
            return el;
          });

          list["price_rules"] = price_rules;
          temp_list.push({
            ...list,
            preview: `${this.baseUrl.baseURL}/product/${list.desktop_img}`,
          });
        });
        this.attribute_list = temp_list;
      }
    },
    async submitAttrList() {
      this.loading.update = true;
      let formData = new FormData();
      let formDataList = new FormData();

      let stringifyJSON = JSON.stringify(this.attribute_list);
      formData.append("data", stringifyJSON);
      const response = await AttributeApi.updateMultipleAttrList(formData);

      // update list image handler
      for (let i = 0; i < this.attribute_list.length; i++) {
        if (this.attribute_list[i].file !== undefined && this.attribute_list[i]._id) {
          formDataList.append("file", this.attribute_list[i].file);
          const res = await AttributeApi.UploadImageList(this.attribute_list[i]._id, formDataList);

          if (res.data.status !== 200) {
            this.$toast.add({
              severity: "failed",
              summary: "Failed to update image",
              detail: `Failed to update image with attribute list name ${this.attribute_list[i].name}`,
              life: 4000,
            });
          } else {
            // success then remove formDataList (file)
            formDataList.delete("file");
          }
        }
      }

      if (response.data.status === 200) {
        const selectNewAttrList = this.attribute_list.filter((attr) => attr.new === true);

        let success_status = 0;
        const newAttr = response.data.data;

        for (let i = 0; i < newAttr.length; i++) {
          const formData = new FormData();
          formData.append("file", selectNewAttrList[i].file);
          const res = await AttributeApi.UploadImageList(newAttr[i]._id, formData);

          if (res.data.status === 200) {
            success_status = success_status + 1;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Something went wrong!",
              detail: res.data.message,
              life: 4000,
            });
            this.showModalSaveAll = false;
          }
        }
        this.loading.update = false;
        this.showModalSaveAll = false;
        this.$router.push("/admin/product-management/attribute");
        // if (isNeAttr && newAttr.length === success_status) {
        // }
      } else {
        this.showModalSaveAll = false;
        this.$toast.add({
          severity: "error",
          summary: "Something went wrong!",
          detail: response.data.message,
          life: 4000,
        });
      }
    },
    async addNewAttrList(index) {
      this.loading.add = true;
      try {
        const createNewList = await AttributeApi.addAttrList(this.attribute_list[index]);
        if (createNewList.data.status === 200) {
          const formData = new FormData();
          formData.append("file", this.attribute_list[index].file);

          // send to server
          await AttributeApi.UploadImageList(createNewList.data.data._id, formData);
          this.$toast.add({
            severity: "success",
            summary: "Attribute Created!",
            detail: "Success add new attribute list",
            life: 4000,
          });

          this.loading.add = false;
          this.getAtrributeDetails();
        }
      } catch (error) {
        console.log(error);
      }
    },
    removeConfirm(id) {
      this.showModalDelete = true;
      this.temp_remove_id = id;
    },
    async removeList(id, index) {
      try {
        this.loading.remove = true;
        if (id) {
          const response = await AttributeApi.Delete(id);
          if (response.data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Successfully deleted!",
              detail: "attribut list deleted!",
              life: 4000,
            });
            this.getAtrributeDetails();
            this.loading.remove = false;
            this.showModalDelete = false;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "failed to deleted!",
              detail: response.data.message,
              life: 4000,
            });
            this.loading.remove = false;
            this.showModalDelete = false;
          }
        } else {
          this.loading.remove = false;
          this.showModalDelete = false;
          this.attribute_list = this.attribute_list.splice(index, 1);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
